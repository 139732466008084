// ==========================================================================
// Base – Variables
// ==========================================================================

// Global Typography
$base-background-color:                         #fff !default;
$base-font-family:                              sans-serif !default;
$base-font-size:                                16 !default;
$base-line-height:                              22 !default;
$base-font-weight:                              400 !default;
$base-font-color:                               #000 !default;
$base-link-color:                               #000 !default;
$base-link-hover-color:                         #000 !default;

// Headings
$base-heading-font-family:                      sans-serif !default;
$base-h1-font-size:                             32 !default;
$base-h1-line-height:                           38 !default;
$base-h1-font-weight:                           700 !default;
$base-h1-color:                                 #000 !default;
$base-h2-font-size:                             26 !default;
$base-h2-line-height:                           32 !default;
$base-h2-font-weight:                           700 !default;
$base-h2-color:                                 #000 !default;
$base-h3-font-size:                             22 !default;
$base-h3-line-height:                           28 !default;
$base-h3-font-weight:                           700 !default;
$base-h3-color:                                 #000 !default;
$base-h4-font-size:                             18 !default;
$base-h4-line-height:                           24 !default;
$base-h4-font-weight:                           700 !default;
$base-h4-color:                                 #000 !default;
$base-h5-font-size:                             16 !default;
$base-h5-line-height:                           22 !default;
$base-h5-font-weight:                           700 !default;
$base-h5-color:                                 #000 !default;
$base-h6-font-size:                             14 !default;
$base-h6-line-height:                           20 !default;
$base-h6-font-weight:                           700 !default;
$base-h6-color:                                 #000 !default;

// Blockquotes
$base-blockquote-font-family:                   sans-serif !default;
$base-blockquote-font-size:                     22 !default;
$base-blockquote-line-height:                   28 !default;

// Code
$base-code-font-family:                         monospace, monospace !default;
$base-code-font-size:                           13 !default;
$base-code-line-height:                         18 !default;
$base-code-color:                               #000 !default;
$base-code-background-color:                    transparent !default;
$base-code-border-color:                        #d7d7d7 !default;

// Forms
$base-input-height:                             36px !default;
$base-input-placeholder-color:                  #999 !default;
$base-input-color:                              #000 !default;
$base-input-background-color:                   #fff !default;
$base-input-background-focus-color:             #fff !default;
$base-input-border-color:                       #ccc !default;
$base-input-border-focus-color:                 #f7c723 !default;
$base-select-box-height:                        36px;

// Grid Alignment
$grid-alignment:                                left;

// Grid Containers
$container:                                     100% !default;
$container-m:                                   720px !default;
$container-l:                                   960px !default;
$container-xl:                                  1120px !default;

// Grid Gutters
$grid-gutter:                                   15px !default;
$grid-gutter-m:                                 15px !default;
$grid-gutter-l:                                 15px !default;
$grid-gutter-xl:                                15px !default;

// Responsive Breakpoints
$breakpoint-m:                                  ($container-m + 20) !default;
$breakpoint-l:                                  ($container-l + 20) !default;
$breakpoint-xl:                                 ($container-xl + 20) !default;
