// ==========================================================================
// Animations – Selective animations from Animate.css - http://daneden.me/animate
// ==========================================================================

// Fade In
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in { animation-name: fadeIn; }

// Fade In Down
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-down { animation-name: fadeInDown; }

// Fade In Down Big
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-down-big { animation-name: fadeInDownBig; }

// Fade In Left
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-30px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-left { animation-name: fadeInLeft; }

// Fade In Left Big
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-left-big { animation-name: fadeInLeftBig; }

// Fade In Right
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(30px, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-right { animation-name: fadeInRight; }

// Fade In Right Big
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-right-big { animation-name: fadeInRightBig; }

// Fade In Up
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-up { animation-name: fadeInUp; }

// Fade In Up Big
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-up-big { animation-name: fadeInUpBig; }