// ==========================================================================
// Base – Code
// ==========================================================================
code, kbd, pre, samp {
  @include font-size($base-code-font-size);
  @include line-height($base-code-line-height);
  word-wrap: break-word;
  font-family: $base-code-font-family;
  color: $base-code-color;
  background-color: $base-code-background-color;
  font-weight: normal;
  padding: 0;
  white-space: pre-wrap;
}
pre {
  padding: 10px;
  overflow: auto;
  border: 1px solid $base-code-border-color;
}